
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.transcript-caption::v-deep {
    display: flex;
    gap: 0.5em;
    padding: 0.25em;
    margin-bottom: 0.25em;

    &.host {
        background-color: $main-background !important;
    }

    &.sponsor {
        background-color: $secondary-color-10 !important;
    }

    &:hover {
        opacity: 0.9;
    }

    .content {
        width: 100%;
    }
}
