
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.inplace-wrapper::v-deep {
    width: 100%;

    &:not(.disabled) {
        cursor: pointer;
    }
}
